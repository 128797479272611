import React from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  AllowedUser,
  Credentials,
  LanguageISO,
  PriceList,
  User,
  Vat,
} from './types';

export const PROD_ENDPOINT = 'https://api.slowvita.it';
export const STAG_ENDPOINT = 'https://dev.api.slowvita.it';
export const DEV_ENDPOINT = 'http://localhost:1337';

export function baseURL(): string {
  const endpoint = window.localStorage.getItem('endpoint');
  if (
    endpoint &&
    (endpoint === PROD_ENDPOINT ||
      endpoint === STAG_ENDPOINT ||
      endpoint === DEV_ENDPOINT)
  )
    return endpoint;
  return PROD_ENDPOINT;
}
export function setEndpoint(endpoint: string): void {
  window.localStorage.setItem('endpoint', endpoint);
}
export function graphQlURL(): string {
  return `${baseURL()}/graphql`;
}
export const THEME_MAIN_COLOR = '#6E6C20';
export const THEME_MAIN_WHITE = '#b7b690';
export const THEME_MAIN_COLOR_DARK = 'rgba(110,108,32,.5)';
export const THEME_SECONDARY_COLOR = '#CA9E67';
export const THEME_SECONDARY_COLOR_DARK = 'rgba(202,158,103,.5)';
export const THEME_BG_COLOR = '#3C3C3B';
export const WEBSITE_URL = 'https://www.slowvita.it';
export const APP_GOOGLE_URL =
  'https://play.google.com/store/apps/details?id=com.waldboth.slowvita';
export const APP_IOS_URL =
  'https://apps.apple.com/us/app/slowvita/id1569165085';
export const APP_NAME = 'SlowVita';
export const BAKERY: 'Sellemond' | 'SlowVita' | 'Bäckerei Frisch' = 'SlowVita';
export const URL = 'www.slowvita.it';
export const SLOGAN = {
  de: 'langsam leben, genießen, innehalten ...',
  it: 'vivi lentamente, divertiti, metti in pausa ...',
  en: 'live slowly, enjoy, pause ...',
};

export const LOGIN_IMAGE_DE = '/whatsSlowvita1_de.png';
export const LOGIN_IMAGE_IT = '/whatsSlowvita2_it.png';
export const LOGIN_IMAGE_EN = '/whatsSlowvita3_en.png';
export const DASHBOARD_IMAGE_DE = '/useSlowvita1_de.png';
export const DASHBOARD_IMAGE_IT = '/useSlowvita2_it.png';
export const DASHBOARD_IMAGE_EN = '/useSlowvita3_en.png';

export enum MESSAGE_TYPE {
  INFO,
  DANGER,
  MUTED,
  SUCCESS,
}

const userContext: {
  user: AllowedUser | null;
  client: null | ApolloClient<NormalizedCacheObject>;
  logout: () => void;
  updateUserResponse: (user: AllowedUser) => void;
  language: LanguageISO;
  vats: Vat[];
  changePageLanguage: (lang: LanguageISO) => void;
  loginFunc: (creds: Credentials | null) => void;
  authMessage: string;
  authMessageType: MESSAGE_TYPE;
  identifier: string;
  password: string;
  numNewPrivates: number;
  decrNumNewPrivates: () => void;
  userPriceLists: PriceList[];
} = {
  user: null,
  client: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateUserResponse: () => {},
  language: 'de',
  vats: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changePageLanguage: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  loginFunc: () => {},
  authMessage: '',
  authMessageType: MESSAGE_TYPE.MUTED,
  identifier: '',
  password: '',
  numNewPrivates: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  decrNumNewPrivates: () => {},
  userPriceLists: [],
};

export const UserContext = React.createContext(userContext);
export const UserContextLoggedIn = React.createContext(
  userContext as UserContextTypeLoggedIn,
);

export declare type UserContextTypeLoggedIn = {
  user: AllowedUser;
  client: ApolloClient<NormalizedCacheObject>;
  logout: () => void;
  updateUserResponse: (user: AllowedUser) => void;
  language: LanguageISO;
  vats: Vat[];
  changePageLanguage: (lang: LanguageISO) => void;
  loginFunc: (creds: Credentials | null) => void;
  authMessage: string;
  authMessageType: MESSAGE_TYPE;
  identifier: string;
  password: string;
  numNewPrivates: number;
  decrNumNewPrivates: () => void;
  userPriceLists: PriceList[];
};
export declare type UserContextTypeLoggedOut = {
  user: User | null;
  client: ApolloClient<NormalizedCacheObject>;
  logout: () => void;
  updateUserResponse: (user: User) => void;
  language: LanguageISO;
  vats: Vat[];
  changePageLanguage: (lang: LanguageISO) => void;
  loginFunc: (creds: Credentials | null) => void;
  authMessage: string;
  authMessageType: MESSAGE_TYPE;
  identifier: string;
  password: string;
  numNewPrivates: 0;
};

export const DAY_IN_MS = 864e5;
export const CENTURY_IN_MS = 3187296000000;
