import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  BasketProduct,
  HostProduct,
  IdentifiableTranslation,
  IDType,
  Language,
  OrderedProduct,
  OrderedProductCompile,
  PriceList,
  Product,
  ProductPriceList,
  User,
} from './types';
import { mutate, query } from '../graphql/networking';
import { isBakery, isHost, isPrivate } from './UserManagement';
import { includesCustom } from './utils';

type ProductTypes = BasketProduct | Product | HostProduct | OrderedProduct;

/**
 * product:{id:ID, name:string, gramPrice:number, description:string, thumb:Media, approxWeight:number, haveToWeight:bool, vat:Vat}
 *
 * hostProduct: {id:ID, displayPrice: number, host: User, baseProduct:product}
 *
 * basketProduct: {id:ID, amount:number, user:User, baseProduct:product}
 *
 * orderedProduct:{id:string, amount:number, measuredWeight:number, currentGramPrice: number, orderedBy:user, currentDisplayPrice:number, currentVat: Vat, missing:bool, order:Order}
 *
 */
export function isBaseProduct(product: ProductTypes): product is Product {
  return product.__typename === 'Product';
}

export function isHostProduct(product: ProductTypes): product is HostProduct {
  return product.__typename === 'HostProduct';
}

export function isBasketProduct(
  product: ProductTypes,
): product is BasketProduct {
  return product.__typename === 'BasketProduct';
}

export function isOrderedProduct(
  product: ProductTypes,
): product is OrderedProduct {
  return product.__typename === 'OrderedProduct';
}

export function priceOfHostProduct(product: HostProduct): number {
  if (product.display_price === -1) {
    return priceOfBaseProduct(product.base_product);
  }
  return product.display_price;
}
function priceOfHostProductAsString(product: HostProduct): string {
  return `${priceOfHostProduct(product).toFixed(2)}€`;
}

export function priceOfOrderedProduct(product: OrderedProduct): number {
  if (product.current_display_price === -1) {
    if (product.base_product.have_to_weight) {
      if (product.measured_weight === -1) {
        return (
          product.current_gram_price *
          product.current_approx_weight *
          product.amount
        );
      }
      return (
        product.current_gram_price * product.measured_weight * product.amount
      );
    }
    return product.current_gram_price * product.amount;
  }
  return product.current_display_price * product.amount;
}

function priceOfOrderedProductAsString(product: OrderedProduct): string {
  const final = priceOfOrderedProduct(product).toFixed(2);
  const { amount } = product;
  const weight =
    product.measured_weight === -1
      ? product.current_approx_weight
      : product.measured_weight;
  const single =
    product.current_display_price === -1
      ? product.base_product.have_to_weight
        ? product.current_gram_price
        : product.current_gram_price * weight
      : product.current_display_price;

  if (
    product.base_product.have_to_weight &&
    product.current_display_price === -1
  )
    return `${
      amount * product.base_product.approx_weight
    }g X ${single}€/kg = ${final}€`;
  return `${amount} X ${single}€ = ${final}€`;
}

function priceOfBasketProduct(product: BasketProduct): number {
  if (product.current_display_price === -1) {
    return priceOfBaseProduct(product) * product.amount;
  }
  return product.current_display_price * product.amount;
}
function priceOfBasketProductAsString(product: BasketProduct): string {
  const final = priceOfBasketProduct(product).toFixed(2);
  const { amount } = product;
  const single =
    product.current_display_price === -1
      ? priceOfBaseProduct(product).toFixed(2)
      : product.current_display_price;

  if (
    product.base_product.have_to_weight &&
    product.current_display_price === -1
  )
    return `${
      amount * product.base_product.approx_weight
    }g X ${single}€/kg = ${final}€`;
  return `${amount} X ${single}€ = ${final}€`;
}

export function baseGramPriceOfBaseProduct(
  product: Product,
  userPriceLists: PriceList[],
): number {
  let gramPrice = product.gram_price;

  if (isBaseProduct(product) || isBasketProduct(product)) {
    const filteredPrices = product.price_lists.filter((pl) =>
      includesCustom(userPriceLists, pl.price_list.id),
    );
    if (filteredPrices.length) {
      const selected =
        filteredPrices[
          filteredPrices.length > 1 && filteredPrices[0].price_list.default
            ? 1
            : 0
        ];
      gramPrice = selected.gram_price;
    }
  }
  return gramPrice;
}

/**
 *
 * @param product
 * @deprecated
 */
export function priceOfBaseProduct(
  product: Product | OrderedProduct | BasketProduct,
): number {
  const gramPrice = isBasketProduct(product)
    ? product.base_product.gram_price
    : isBaseProduct(product)
    ? product.gram_price
    : product.current_gram_price;
  const haveToWeight = isBaseProduct(product)
    ? product.have_to_weight
    : product.base_product.have_to_weight;
  const approxWeight = isBaseProduct(product)
    ? product.approx_weight
    : product.base_product.approx_weight;
  if (haveToWeight) {
    return gramPrice * approxWeight;
  }
  return gramPrice;
}

/**
 *
 * @param product
 * @returns {string}
 */
export function showPrice<T extends ProductTypes>(product: T): string {
  if (isHostProduct(product)) {
    return priceOfHostProductAsString(product);
  }
  if (isOrderedProduct(product)) {
    return priceOfOrderedProductAsString(product);
  }
  if (isBasketProduct(product)) {
    return priceOfBasketProductAsString(product);
  }
  return `${(product.gram_price * (product.have_to_weight ? 1000 : 1)).toFixed(
    2,
  )}€${product.have_to_weight ? '/kg' : ''}`;
}
// lol
/**
 *
 * @param product
 * @param basketProducts
 *
 * @return boolean
 */
export function isProductInBasket(
  product: BasketProduct | Product | HostProduct | OrderedProduct,
  basketProducts: BasketProduct[],
): boolean {
  return getBasketAmountOfProduct(product, basketProducts) !== 0;
}

/**
 *
 * @param product
 * @param basketProducts
 *
 * @return string
 */
export function getBasketAmountOfProduct<T extends ProductTypes>(
  product: T,
  basketProducts: BasketProduct[],
): number {
  const id = getBaseProductId(product);
  for (const basketProduct of basketProducts) {
    if (basketProduct.base_product.id === id) {
      return basketProduct.amount;
    }
  }
  return 0;
}

export function getBaseProduct(product: ProductTypes): Product {
  if (isBaseProduct(product)) return product;
  return product.base_product;
}

export function getBaseProductId(product: ProductTypes): IDType {
  return getBaseProduct(product).id;
}

export function getBasketProductIdOfProduct(
  product: BasketProduct | Product | HostProduct | OrderedProduct,
  basketProducts: BasketProduct[],
): string {
  const id = getBaseProductId(product);
  if (isProductInBasket(product, basketProducts)) {
    for (const basketProduct of basketProducts) {
      if (basketProduct.base_product.id === id) {
        return basketProduct.id;
      }
    }
    return id;
  }
  return id;
}

export function getAllProductsOfBakery(
  apolloClient: ApolloClient<NormalizedCacheObject>,
  bakeryID: IDType,
  userID: IDType,
): Promise<Product[]> {
  return new Promise((resolve) => {
    query(apolloClient, 'getPriceLists', {
      bakery: bakeryID,
    }).then((priceLists) => {
      if (priceLists) {
        const ofUser = priceLists
          .filter((priceList) => isInPriceListClient(userID, priceList))
          .map((pl) => pl.id);
        query(apolloClient, 'bakeryProductsByPriceList', {
          bakeryID,
          offset: 0,
          limit: 1000,
          priceLists: ofUser,
        }).then((products) => {
          resolve(products || []);
        });
      }
    });
  });
}

export const PRODUCT_LIST_MAX = 500;

export function getProductsVisibleForUser(
  apolloClient: ApolloClient<NormalizedCacheObject>,
  user: User,
  offset = 0,
): Promise<Product[] | HostProduct[]> {
  return new Promise((resolve) => {
    if (isBakery(user) || isPrivate(user)) {
      query(apolloClient, 'bakeryProducts', {
        bakeryID: isBakery(user) ? user.id : user.bakery.id,
        offset,
        limit: PRODUCT_LIST_MAX,
      }).then((products) => {
        resolve(products || []);
      });

      /*
        restQuery('products_rest', {
          bakery: user.id,
          _start: offset,
          _limit: PRODUCT_LIST_MAX,
          deleted: false
      }, true)
          .then(function (products) {
              resolve(products ? products : []);
          });
       */
    } else if (isHost(user)) {
      query(apolloClient, 'hostProducts', {
        hostID: user.id,
        offset,
        limit: PRODUCT_LIST_MAX,
      }).then((products) => {
        resolve(products || []);
      });
    }
  });
}

export function getHostProductFromBase(
  apolloClient: ApolloClient<NormalizedCacheObject>,
  host: User,
  baseID: string,
): Promise<HostProduct | null> {
  return new Promise((resolve) => {
    query(apolloClient, 'hostProductsByBase', {
      hostID: host.id,
      baseProductID: baseID,
    }).then((products) => {
      resolve(products && products.length ? products[0] : null);
    });
  });
}

export function hostProductCount(
  apolloClient: ApolloClient<NormalizedCacheObject>,
  hostID: string,
): Promise<number> {
  return new Promise((resolve) => {
    query(apolloClient, 'countHostProducts', { hostID }).then(
      (countResponse) => {
        resolve(countResponse || 0);
      },
    );
  });
}

export function combineAndSortOrderedProducts(
  orderedProducts: OrderedProduct[],
): OrderedProductCompile[] {
  const retval: OrderedProductCompile[] = [];
  let currentOrderer: IDType = '-1';
  orderedProducts
    .sort(
      (a, b) => parseInt(a.ordered_by.id, 10) - parseInt(b.ordered_by.id, 10),
    )
    .forEach((op) => {
      if (op.ordered_by.id === currentOrderer) {
        let idx = -1;
        retval.forEach((retvalProduct, index) => {
          if (retvalProduct.base_product.id === op.base_product.id) {
            idx = index;
          }
        });
        if (idx === -1) {
          retval.push({ ...op, ids: [op.id], amounts: [op.amount] });
        } else {
          retval[idx].amount += op.amount;
          retval[idx].ids.push(op.id);
          retval[idx].amounts.push(op.amount);
        }
      } else {
        currentOrderer = op.ordered_by.id;
        retval.push({ ...op, ids: [op.id], amounts: [op.amount] });
      }
    });
  return retval;
}

export function sortFuncProducts<T extends Product | HostProduct>(
  a: T,
  b: T,
): number {
  const dpa = isHostProduct(a) ? a.base_product.display_order : a.display_order;
  const dpb = isHostProduct(b) ? b.base_product.display_order : b.display_order;

  return dpa - dpb;
}

export function isInPriceList<T extends Product | HostProduct>(
  product: T,
  list: IDType,
): false | ProductPriceList {
  if (isBaseProduct(product)) {
    const filtered = product.price_lists.find(
      (p) => 'price_list' in p && p.price_list.id === list,
    );
    return filtered || false;
  }
  const filtered = product.base_product.price_lists.find(
    (p) => 'price_list' in p && p.price_list.id === list,
  );
  return filtered || false;
}
export function isInPriceListClient(user: IDType, list: PriceList): boolean {
  return includesCustom(list.visible_for, user);
}

export function filterByPriceList(
  products: Product[],
  list: IDType,
  reverse = false,
): Product[] {
  return products.filter((product) =>
    reverse ? !isInPriceList(product, list) : isInPriceList(product, list),
  );
}
export function filterByPriceListsClient(
  products: Product[],
  lists: PriceList[],
  client: IDType,
): Product[] {
  const clientLists = lists
    .filter((pl) => isInPriceListClient(client, pl))
    .map((p) => p.id);
  return products.filter((product) => {
    let val = false;
    clientLists.forEach((pl) => {
      if (isInPriceList(product, pl)) val = true;
    });
    return val;
  });
}

export function getPriceListsOfUser(
  priceLists: PriceList[],
  user: IDType,
): IDType[] {
  const selectedPriceLists: string[] = [];
  priceLists.forEach((pl) => {
    if (isInPriceListClient(user, pl)) selectedPriceLists.push(pl.id);
  });
  return selectedPriceLists;
}
/**
 *
 * @param client
 * @param userID
 * @param translation
 * @param lang
 *
 * @param identifier
 * @return Promise
 */
export function createTranslation(
  client: ApolloClient<NormalizedCacheObject>,
  userID: string,
  translation: string,
  lang: Language,
  identifier?: string,
): Promise<IdentifiableTranslation> {
  return new Promise((resolve, reject) => {
    mutate(client, 'createTranslation', {
      bakeryID: userID,
      translation,
      langID: lang.id,
    }).then((newTranslation) => {
      if (newTranslation) {
        resolve({ ...newTranslation, identifier: identifier + lang.short });
      } else {
        reject(new Error(identifier + lang.short));
      }
    });
  });
}
